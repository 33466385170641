export const menu_data = {
  default: {
    "path":"/map_view",
    "title":"Map view", 
    "iframe_src": "https://data.apps.fao.org/?clean&https://data.apps.fao.org/catalog/terriajs/config/7db765f4-9d30-4e81-86af-9a0b563a72bf.json"
  },
  region_gap_assessment: {
    "path":"/graph_view",
    "title":"Graph view",
    "iframe_src": "https://tableau.apps.fao.org/views/Regional_assessment2/Introduction?:embed=yes&:showVizHome=no&:toolbar=no"
  },
  /* country_gap_assessment: {
    "path":"/country_gap_assessment",
    "title":"Country assessment",
    "iframe_src": "https://tableau.apps.fao.org/views/GapAssessment_Country_EC/GapAssessment_Country?:embed=yes"
  }, */ 
  resources: {
    "path":"/resources",
    "title":"Resources", 
    /* "iframe_src": "https://tableau.apps.fao.org/views/Resources_NDC/NDC_Resources?:embed=yes" */
    "iframe_src": "https://tableau.apps.fao.org/views/Resources_ETF/Dashboard1?:embed=yes&:showVizHome=no&:toolbar=no"
  },
  how_to_use: {
    "path":"/how_to_use",
    "title":"How to use", 
    /* "iframe_src": "https://tableau.apps.fao.org/views/Resources_NDC/NDC_Resources?:embed=yes" */
    "iframe_src": "https://data.apps.fao.org/catalog/dataset/27bcb415-f62a-472b-8fdc-a49cee892285/resource/5d35b671-fb22-46f4-8e2f-92d6f49e5e55/download/how_to_use_v3.htm"
  },  
    abbreviations: {
    "path":"/abbreviations",
    "title":"Abbreviations", 
    /* "iframe_src": "https://tableau.apps.fao.org/views/Resources_NDC/NDC_Resources?:embed=yes" */
    "iframe_src": "https://data.apps.fao.org/catalog/dataset/27bcb415-f62a-472b-8fdc-a49cee892285/resource/4f685569-d821-4e3a-a2d7-d9f5efe75743/download/abbreviations_v01.htm"
  }, 
};
